import React from 'react';

const Modal = ({showModal, setShowModal, title, subtitle, content, label}) => {
  return (
    <div className="z-50">
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mx-5">
            <div className="relative w-auto my-6 lg:mx-auto lg:min-w-3xl">
              <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-end justify-end mt-6 mr-6 lg:mt-8 lg:mr-8">
                  <button onClick={() => setShowModal(false)}>
                    <i className="icon close text-primary ml-auto text-2xl" />
                  </button>
                </div>
                <div className="lg:mx-[135px] mx-6 mb-[35px] flex flex-col gap-8 justify-center items-center text-center">
                  {title && (
                    <h1 className="hidden sm:block lg:flex text-[40px] font-boldie leading-[56px] tracking-[0.8px] text-secondary">{title}</h1>
                  )}
                  <h4 className="text-[24px] lg:text-[20px] leading-[34px] font-boldie lg:leading-7 lg:mt-0 mt-9 tracking-[0.48px] lg:tracking-[0.4px] text-secondary">
                    {subtitle}
                  </h4>
                  <p className="text-primary leading-[22px] tracking-[0.32px] max-w-[530px]">{content}</p>
                  <button onClick={() => setShowModal(false)} className="p-[17px] bg-primary min-w-[284px] mb-0 lg:mb-[60px] text-white uppercase">
                    {label}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};

export default Modal;
