import React from 'react';

const HtmlWidget = ({widgetData}) => {
  const {Title, Html} = widgetData;

  return (
    <div className="flex flex-col items-start py-6 max-lg:px-6 lg:w-[952px] lg:ml-auto lg:mr-auto">
      <h1 className="lg:text-[24px] text-[20px] font-normal lg:leading-[34px] leading-7 lg:tracking-[0.48px] tracking-[0.4] lg:mb-8 mb-6">{Title}</h1>
      <div
        className="text-[16px] lg:text-[20px] font-normal lg:leading-7 leading-[22px] lg:tracking-[0.4px] tracking-[0.32px] text-primary"
        dangerouslySetInnerHTML={{__html: Html}}
      />
    </div>
  );
};

export default HtmlWidget;
