import React from 'react';
import Links from '../links';

const Button = ({className, widgetData = {}}) => {
  const {Title, BackgroundColor, ButtonText, Url} = widgetData;

  return (
    <div style={{backgroundColor: BackgroundColor}} className="inline-flex flex-col w-full max-sm:gap-6 lg:py-[125px] lg:pl-[267px] px-6 py-[52px]">
      {Title && (
        <div className="flex">
          <h3 className="hidden sm:block text-[24px] font-boldie leading-[34px] tracking-[0.48px] mb-6 text-primary">{Title}</h3>
          <h5 className="hidden max-sm:block text-[16px] font-boldie leading-[22px] tracking-[0.32px] text-primary">{Title}</h5>
        </div>
      )}
      <div className="flex">
        {ButtonText && (
          <Links
            href={Url}
            className={`${className}, lg:p-6 p-4 uppercase font-boldie leading-[22px] tracking-[0.32px] bg-white hover:bg-primary hover:text-white text-primary max-sm:text-center outline-1 outline-primary outline-double`}>
            {ButtonText}
          </Links>
        )}
      </div>
    </div>
  );
};

export default Button;
