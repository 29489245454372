import React, {useMemo, useEffect, useRef} from 'react';
import {Drawer} from '@/components';
import Image from 'next/legacy/image';

const PopupWidget = ({isOpen, setIsOpen, widgetData}) => {
  const {Title, Image: DrawerImage, Text, PopupType, Subtitle} = widgetData || {};

  const drawerContentRef = useRef(null);

  // Function to scroll the drawer content to the top
  const resetScrollPosition = () => {
    if (drawerContentRef.current) {
      drawerContentRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    // Reset scroll position when isOpen changes
    if (!isOpen) {
      resetScrollPosition();
    }
  }, [isOpen]);

  const renderVertical = useMemo(
    () => (
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="w-full flex ml-auto absolute z-10 justify-end">
          <button className="pr-8 pt-8" onClick={() => setIsOpen(false)}>
            <i className="icon close text-primary ml-auto text-2xl" />
          </button>
        </div>
        <div className="flex lg:ml-12 ml-0 h-full">
          <div
            ref={drawerContentRef}
            className="lg:flex-1 lg:mr-6 mr-0 lg:mb-[67px] mb-0 mx-[23px] overflow-hidden gap-10 lg:gap-0 flex flex-col max-sm:overflow-y-auto max-sm:relative max-sm:no-scrollbar">
            <div className="flex-grow-0 lg:pt-[167px] pt-20 lg:pb-6 pb-0">
              {DrawerImage && (
                <div className="hidden max-sm:block">
                  <Image src={DrawerImage} width={328} height={326} alt=""></Image>
                </div>
              )}
              {/* <div className="hidden sm:block lg:flex flex-row gap-4 mb-[52px]">
                <i className="icon at-the-rate text-primary text-[32px]"></i>
                <i className="icon linkedin text-primary text-[32px]"></i>
                <i className="icon twitter text-primary text-[32px] ml-[12px]"></i>
              </div> */}
              {Title && (
                <>
                  <h1 className="lg:text-[40px] text-[32px] font-boldie lg:leading-[56px] leading-[44px] lg:tracking-[0.8px] tracking-[0.64px] text-primary lg:mb-[8px]">
                    {Title}
                  </h1>
                  <p className="text-[20px] leading-7 tracking-[0.4px] text-secondary">{Subtitle}</p>
                </>
              )}
            </div>
            {Text && (
              <div className="overflow-y-auto relative no-scrollbar">
                <div
                  className="lg:text-[20px] text-[16px] leading-[22px] lg:leading-7 tracking-[0.32px] lg:tracking-[0.4px] text-primary relative z-10"
                  dangerouslySetInnerHTML={{__html: Text}}
                />
                <div className="sticky bottom-0 left-0 bg-white bg-opacity-50 w-full h-10 z-20"></div>
              </div>
            )}
            {/*   <div className="hidden max-sm:block flex-row gap-4 mb-5">
              <i className="icon at-the-rate text-primary text-[32px]"></i>
              <i className="icon linkedin text-primary text-[32px]"></i>
              <i className="icon twitter text-primary text-[32px] ml-[12px]"></i>
            </div> */}
          </div>
          {DrawerImage && (
            <div className="hidden lg:flex-1 sm:block">
              <Image src={DrawerImage} width={476} height={1039} alt=""></Image>
            </div>
          )}
        </div>
      </Drawer>
    ),
    [DrawerImage, Text, Title, isOpen, setIsOpen],
  );

  const renderHorizontal = useMemo(
    () => (
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="w-full flex ml-auto absolute z-10 justify-end">
          <button className="pr-8 pt-8" onClick={() => setIsOpen(false)}>
            <i className="icon close text-primary ml-auto text-2xl" />
          </button>
        </div>
        <div className="flex ml-0 h-full px-6 pb-12">
          <div ref={drawerContentRef} className="overflow-y-auto no-scrollbar h-screen">
            {Title && <h1 className="text-primary pt-[96px] mb-6 lg:mb-0 leading-[56px] text-[40px] font-[540] tracking-[0.8px]">{Title}</h1>}
            {Subtitle && <p className=" max-sm:hidden text-[14px] mb-6 text-gray-500 font-normal leading-5 tracking-[0.28px] ">{Subtitle}</p>}
            {DrawerImage && (
              <div className="lg:flex-1 mb-10">
                <Image src={DrawerImage} width={877} height={298} alt=""></Image>
              </div>
            )}
            {Text && (
              <div className=" flex-1 h-screen">
                <p className="text-[20px] font-normal leading-7 tracking-[0.4px] text-primary">
                  <div dangerouslySetInnerHTML={{__html: Text}} />
                </p>
              </div>
            )}
          </div>
        </div>
      </Drawer>
    ),
    [DrawerImage, Text, Title, isOpen, setIsOpen, Subtitle],
  );

  const renderPopupWidget = useMemo(() => {
    if (PopupType === 1) return renderHorizontal;
    return renderVertical;
  }, [PopupType, renderHorizontal, renderVertical]);

  return renderPopupWidget;
};

export default PopupWidget;
