import React, {useState} from 'react';
import WholesaleContactForm from '../wholesaleContactForm';
import SalesContactForm from '../salesContactForm';
import PartnerForm from '../partnerForm';

const FormNavigation = ({widgetData = {}}) => {
  const {Title, BackgroundColor, FormBtnType, BtnText} = widgetData;

  const [isOpen, setIsOpen] = useState(false);

  const renderForm = FormBtnType => {
    if (FormBtnType === '1') {
      return <WholesaleContactForm isOpen={isOpen} setIsOpen={setIsOpen} />;
    } else if (FormBtnType === '2') {
      return <PartnerForm isOpen={isOpen} setIsOpen={setIsOpen} />;
    } else if (FormBtnType === '3') {
      return <SalesContactForm isOpen={isOpen} setIsOpen={setIsOpen} />;
    }
    return null;
  };

  return (
    <div className="flex justify-center">
      <div
        className="flex justify-between border border-[#D0D1D3] md:px-[52px] md:w-[952px] px-6 lg:py-8 py-3 mx-6 mt-16"
        style={{
          backgroundColor: BackgroundColor,
        }}>
        <div>
          <h4 className="text-primary text-[20px] font-boldie leading-7 tracking-[0.4px] py-4">{Title}</h4>
        </div>
        <div className="flex lg:flex-row flex-col text-[14px] lg:text-[16px] gap-2 font-medium leading-5 tracking-[0.32px] text-primary">
          {FormBtnType !== 0 && (
            <button
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className="uppercase text-primary font-boldie text-[16px] leading-[22px] tracking-[0.32px] border border-primary p-[17px] lg:ml-auto mt-4 lg:mt-0">
              {BtnText}
            </button>
          )}
          {renderForm(FormBtnType)}
        </div>
      </div>
    </div>
  );
};
export default FormNavigation;
