import React, {useEffect, useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useLanguage} from '@/hooks/LanguageContext';

const BusinessInformation = ({widgetData = {}}) => {
  const {translate} = useLanguage();
  const {Title, TaxName, TaxNumber, RegistrationNumber, RegistryOffice, Adress} = widgetData;
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied === true) {
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  }, [copied]);

  return (
    <div className="flex justify-center mx-6 lg:mx-0 lg:mt-[112px] mt-[72px] mb-[72px] lg:mb-[172px]">
      <div className="flex flex-col gap-[72px] text-primary">
        <div className="flex flex-col">
          {Title && (
            <p className=" text-xl lg:[text-[40px] font-boldie leading-7 lg:leading-[56px] tracking-[0.4px] lg:tracking-[0.8px] mb-6">{Title}</p>
          )}
          {Adress && (
            <div className="flex flex-row">
              <p id="address" className="font-boldie text-base lg:text-xl leading-[22px] lg:leading-7 tracking-[0.32px] lg:tracking-[0.4px] mb-2">
                {translate('Address')}: <span className="font-normal">{Adress}</span>
              </p>
              <CopyToClipboard onCopy={() => setCopied(true)} text={Adress}>
                <button className={`${copied ? ' text-green-800' : 'text-primary'}`}>
                  <i className="icon lg:text-[28px] text-xl lg:ml-3 copy-to-clipboard"></i>
                </button>
              </CopyToClipboard>
            </div>
          )}
          {TaxName && (
            <p className="font-boldie text-base lg:text-xl leading-[22px] lg:leading-7 tracking-[0.32px] lg:tracking-[0.4px] mb-2">
              {translate('Tax Name')}: <span className="font-normal">{TaxName}</span>
            </p>
          )}
          {TaxNumber && (
            <div className="flex flex-row">
              <p id="tax-number" className="font-boldie text-base lg:text-xl leading-[22px] lg:leading-7 tracking-[0.32px] lg:tracking-[0.4px] mb-2">
                {translate('Tax Number')}: <span className="font-normal">{TaxNumber}</span>
              </p>
              <CopyToClipboard onCopy={() => setCopied(true)} text={TaxNumber}>
                <button className={`${copied ? ' text-green-800' : 'text-primary'}`}>
                  <i className="icon lg:text-[28px] text-xl ml-3 copy-to-clipboard"></i>
                </button>
              </CopyToClipboard>
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <p className="text-[20px] font-boldie leading-7 lg:leading-[43px] tracking-[0.4px] lg:tracking-[0.48px] mb-6">Ticari Sicil Bilgileri</p>
          {RegistrationNumber && (
            <p className="font-boldie text-base lg:text-xl leading-[22px] lg:leading-7 tracking-[0.32px] lg:tracking-[0.4px] mb-2">
              {translate('Registration Number')}: <span className="font-normal">{RegistrationNumber}</span>
            </p>
          )}
          {RegistryOffice && (
            <p className="font-boldie text-base lg:text-xl leading-[22px] lg:leading-7 tracking-[0.32px] lg:tracking-[0.4px]">
              {translate('Registry Office')}: <span className="font-normal">{RegistryOffice}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessInformation;
