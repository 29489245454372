import React, {useMemo} from 'react';
import Image from 'next/image';
import Links from '../links';

const Roadmap = ({widgetData = {}}) => {
  const {Items} = widgetData;

  const renderFrame = useMemo(() => {
    return (
      <>
        {Items?.map((item, index) =>
          index % 2 === 0 ? (
            <div className="flex lg:flex-row flex-col text-primary text-[16px] lg:text-[20px] items-center gap-[72px] lg:gap-[122px]" key={index}>
              <div className="flex flex-col mb-[72px] lg:py-[100px] lg:mb-0 gap-3 lg:gap-4 text-center items-center lg:w-[464px] px-[23px] lg:px-0">
                <div className="hidden max-lg:block">
                  <Image src={item.Image} width={160} height={113} alt=""></Image>
                </div>
                <Links
                  href={item?.Url}
                  className="font-boldie text-[14px] lg:text-[16px] lg:leading-[28px] leading-[20px] tracking-[0.28px] lg:tracking-[0.4px]">
                  {item?.Title}
                </Links>
                <p className="lg:text-[20px] text-[16px] lg:leading-7 leading-[22px] lg:tracking-[0.4px] tracking-[0.32px] font-normal">
                  {item?.Description}
                </p>
              </div>
              <div className="hidden lg:block relative border-solid border-[1px] border-primary lg:flex-1 lg:flex lg:self-stretch my-0 mx-auto">
                {index === 0 ? <div className="absolute -ml-[6px] -mt-2 left-1/2 w-3 h-3 bg-primary rounded-full origin-[50%_50%]"></div> : null}
                <div className="absolute -ml-3 top-1/2 left-1/2 w-6 h-6 bg-primary rounded-full origin-[50%_50%]"></div>

                {index === Items?.length - 1 ? (
                  <div className="absolute -ml-[6px] mt-[550px] left-1/2 w-3 h-3 bg-primary rounded-full origin-[50%_50%]"></div>
                ) : null}
              </div>

              <div className="hidden lg:block lg:py-[100px]">
                <Image src={item?.Image} width={464} height={634} alt=""></Image>
              </div>
            </div>
          ) : (
            <div
              className="flex lg:flex-row flex-col mb-[72px] lg:mb-0 px-[23px] lg:px-0 text-primary text-[16px] lg:text-[20px] items-center gap-[72px] lg:gap-[122px]"
              key={index}>
              <div className="hidden lg:block">
                <Image src={item?.Image} width={464} height={634} alt=""></Image>
              </div>
              <div className="hidden min-lg:block relative border-solid border-[1px] border-primary lg:flex-1 lg:flex lg:self-stretch my-0 mx-auto">
                <div className="absolute -ml-3 top-1/2 left-1/2 w-6 h-6 bg-primary rounded-full origin-[50%_50%]"></div>
              </div>
              <div className="flex flex-col items-center gap-4 text-center lg:w-[464px]">
                <div className="hidden max-lg:block">
                  <Image src={item?.Image} width={160} height={113} alt=""></Image>
                </div>
                <Links
                  href={item?.Url}
                  className="font-boldie text-[14px] lg:text-[16px] lg:leading-[28px] leading-[20px] tracking-[0.28px] lg:tracking-[0.4px]">
                  {item?.Title}
                </Links>
                <p className="lg:text-[20px] text-[16px] lg:leading-7 leading-[22px] lg:tracking-[0.4px] tracking-[0.32px] font-normal">
                  {item?.Description}
                </p>
                {index === Items?.length - 1 ? (
                  <div className="hidden lg:block absolute -ml-[6px] mt-[220px] left-1/2 w-3 h-3 bg-primary rounded-full origin-[50%_50%]"></div>
                ) : null}
              </div>
            </div>
          ),
        )}
      </>
    );
  }, [Items]);

  return <div className="flex flex-col lg:items-start items-center mt-[72px] lg:w-[952px] ml-auto mr-auto text-primary">{renderFrame}</div>;
};

export default Roadmap;
