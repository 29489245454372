import React, {useEffect, useState, useRef} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Drawer from '../drawer';
import {useForm, Controller} from 'react-hook-form';
import {RadioButton, TextArea, TextInput} from '../inputs';
import Dropdown from '../dropdown';
import {useGetCityListQuery, useGetDistrictListQuery, useGetTownListQuery} from '@/store/services';
import {DevTool} from '@hookform/devtools';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {Modal} from '@/components';
import {useLanguage} from '@/hooks/LanguageContext';
import {useSendEmailMutation} from '@/store/services';

const schema = yup
  .object({
    FirstName: yup.string().required('İsim alanı boş bırakılamaz.'),
    LastName: yup.string().required('Soyisim alanı boş bırakılamaz.'),
    IdentityNo: yup
      .string()
      .required('T.C. Kimlik Numarası alanı boş bırakılamaz.')
      .matches(/^[0-9]+$/, 'Sadece sayısal değer girilebilir.')
      .min(11, 'T.C. Kimlik Numarası alanı 11 haneli olmalıdır.')
      .max(11, 'T.C. Kimlik Numarası alanı 11 haneli olmalıdır.'),
    Phone: yup.string().matches(new RegExp('[0-9]{7}')),
    Email: yup.string().email('Email formatına uygun olmalıdır.').required('Email alanı boş bırakılamaz.'),
    Fax: yup.string().required('Fax alanı boş bırakılamaz.'),
    Message: yup.string().required('Mesaj alanı boş bırakılamaz.'),
    CompanyName: yup.string().required('Firma adı alanı boş bırakılamaz.'),
    CompanyActivity: yup.string().required('Firma faaliyet alanı boş bırakılamaz.'),
    CompanyRegistry: yup.string().required('Firma sicil numarası boş bırakılamaz.'),
    Bank: yup.string().required('Firmanın çalıştığı banka boş bırakılamaz.'),
    BoynerType: yup.string().required('Boyner tipi boş bırakılamaz.'),
    M2: yup.number().moreThan(0).required('Metrekaresi boş bırakılamaz.'),
    NumberOfFloors: yup.number().moreThan(0).required('Kat sayısı boş bırakılamaz.'),
    FloorHeight: yup.number().moreThan(0).required('Kat yüksekliği boş bırakılamaz.'),
    Avm: yup.string().required('Mağazanın konumlanacağı AVM boş bırakılamaz.'),
    city: yup.string().required('Şehir boş bırakılamaz.'),
    district: yup.string().required('İlçe boş bırakılamaz.'),
    town: yup.string().required('Mahalle boş bırakılamaz.'),
    Address: yup.string().required('Adres boş bırakılamaz.'),
  })
  .required();

const PartnerForm = ({isOpen, setIsOpen}) => {
  const recaptchaRef = useRef(null);
  const {translate, selectedLanguage} = useLanguage();
  const [city, setCity] = useState(-1);
  const [district, setDistrict] = useState(-1);
  const [town, setTown] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [sendEmail, {isSuccess}] = useSendEmailMutation();

  const {data: cityData} = useGetCityListQuery();

  const {data: districtData} = useGetDistrictListQuery(
    {
      cityId: city?.id,
    },
    {
      skip: city?.id > 0 ? false : true,
    },
  );

  const {data: townData} = useGetTownListQuery(
    {
      districtId: district?.id,
    },
    {
      skip: district?.id > 0 ? false : true,
    },
  );

  const cities = cityData
    ?.filter(city => city.Value !== '' && city.Value !== null && city.Value.length > 0)
    ?.map(city => {
      return {id: city.Value, value: city.Text};
    });

  const districts = districtData
    ?.filter(district => district.Value !== '' && district.Value !== null && district.Value.length > 0)
    ?.map(district => {
      return {id: district.Value, value: district.Text};
    });

  const towns = townData
    ?.filter(town => town.Value !== '' && town.Value !== null && town.Value.length > 0)
    ?.map(town => {
      return {id: town.Value, value: town.Text};
    });

  useEffect(() => {
    if (city?.id) {
      setDistrict(-1);
      setTown(-1);
    }
  }, [city]);

  useEffect(() => {
    setTown(-1);
  }, [district, city]);

  useEffect(() => {
    if (isSuccess) {
      setIsOpen(false);
      setShowModal(true);
    }
  }, [isSuccess]);

  const {
    control,
    trigger,
    getValues,
    reset,
    formState: {errors},
  } = useForm({resolver: yupResolver(schema)});

  async function handleCaptchaSubmission(token) {
    try {
      await fetch('/api/validate-recaptcha', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({token: token}),
      });
      setIsVerified(true);
    } catch (e) {
      setIsVerified(false);
    }
  }

  const onSubmit = async () => {
    const formValues = getValues();
    const triggered = await trigger();
    if (triggered) {
      await sendEmail({data: JSON.stringify({...formValues, emailType: 2, city: city?.value, dictrict: district?.value, town: town?.value})});
    }
  };

  useEffect(() => {
    if (isOpen) {
      reset();
      recaptchaRef.current.reset();
      setIsVerified(false);
    }
  }, [isOpen]);

  return (
    <>
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className=" overflow-y-auto h-screen">
          <div className="hidden lg:block lg:flex items-end justify-end mt-6 mr-6 lg:mt-8 lg:mr-8">
            <button onClick={() => setIsOpen(false)}>
              <i className="icon close text-primary ml-auto text-2xl" />
            </button>
          </div>
          <div className="flex flex-row lg:pl-0 lg:py-0 py-3 pl-4 items-center">
            <button onClick={() => setIsOpen(false)} className="hidden max-lg:block">
              <i className="icon left-chevron text-[26px]" />
            </button>
            <p className="text-center text-[18px] lg:text-[40px] ml-[26px] lg:ml-[50px] lg:leading-[56px] font-boldie leading-[22px] lg:tracking-[0.8px] tracking-[0.36px] text-primary">
              {translate('BecomeABusinessPartnerContactForm')}
            </p>
          </div>
          <form className="mt-5 lg:mt-8 lg:mx-[50px] mx-4" onSubmit={e => e.preventDefault()}>
            <h6 className="text-[16px] lg:text-[20px] font-boldie lg:leading-7 leading-5 lg:tracking-[0.4px] tracking-[0.28px] mb-3">
              {translate('ContactInformation')}
            </h6>
            <div className="flex flex-col gap-3">
              <div className="grid lg:grid-cols-3 grid-cols-1 gap-3">
                <Controller
                  name="FirstName" // Name of the field
                  control={control} // Pass the register function from useForm
                  defaultValue="" // Set the initial value here if needed
                  render={({field: {onChange, onBlur, value, name, ref}}) => (
                    <div className="flex flex-col">
                      <TextInput
                        onChange={onChange}
                        onBlur={onBlur}
                        name={name}
                        value={value}
                        ref={ref}
                        placeholder={translate('YourName')}
                        isError={errors?.FirstName}
                      />
                    </div>
                  )}
                />

                <Controller
                  name="LastName" // Name of the field
                  control={control} // Pass the register function from useForm
                  defaultValue="" // Set the initial value here if needed
                  render={({field: {onChange, onBlur, value, name, ref}}) => (
                    <TextInput
                      onChange={onChange}
                      onBlur={onBlur}
                      name={name}
                      value={value}
                      ref={ref}
                      placeholder={translate('YourSurname')}
                      isError={errors?.LastName}
                    />
                  )}
                />
                <Controller
                  name="IdentityNo" // Name of the field
                  control={control} // Pass the register function from useForm
                  defaultValue="" // Set the initial value here if needed
                  render={({field: {onChange, onBlur, value, name, ref}}) => (
                    <TextInput
                      onChange={onChange}
                      onBlur={onBlur}
                      name={name}
                      value={value}
                      ref={ref}
                      placeholder={translate('YourIDNumber')}
                      isError={errors?.IdentityNo}
                    />
                  )}
                />
              </div>

              <div className="grid lg:grid-cols-3 grid-cols-1 gap-3">
                <Controller
                  name="Phone" // Name of the field
                  control={control} // Pass the register function from useForm
                  defaultValue="" // Set the initial value here if needed
                  render={({field: {onChange, onBlur, value, name, ref}}) => (
                    <TextInput
                      onChange={onChange}
                      onBlur={onBlur}
                      name={name}
                      value={value}
                      ref={ref}
                      placeholder={translate('YourPhone')}
                      isError={errors?.Phone}
                    />
                  )}
                />
                <Controller
                  name="Email" // Name of the field
                  control={control} // Pass the register function from useForm
                  defaultValue="" // Set the initial value here if needed
                  render={({field: {onChange, onBlur, value, name, ref}}) => (
                    <TextInput
                      onChange={onChange}
                      onBlur={onBlur}
                      name={name}
                      value={value}
                      ref={ref}
                      placeholder={translate('Email')}
                      isError={errors?.Email}
                    />
                  )}
                />
                <Controller
                  name="Fax" // Name of the field
                  control={control} // Pass the register function from useForm
                  defaultValue="" // Set the initial value here if needed
                  render={({field: {onChange, onBlur, value, name, ref}}) => (
                    <TextInput
                      onChange={onChange}
                      onBlur={onBlur}
                      name={name}
                      value={value}
                      ref={ref}
                      placeholder={translate('YourFaxNumber')}
                      isError={errors?.Fax}
                    />
                  )}
                />
              </div>

              <Controller
                name="Message" // Name of the field
                control={control} // Pass the register function from useForm
                defaultValue="" // Set the initial value here if needed
                render={({field: {onChange, onBlur, value, name, ref}}) => (
                  <TextArea
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    value={value}
                    ref={ref}
                    placeholder={translate('YourMessage')}
                    isError={errors?.Message}
                  />
                )}
              />
              <h6 className="text-[16px] lg:text-[20px] mt-5 font-boldie lg:leading-7 leading-5 lg:tracking-[0.4px] tracking-[0.28px]">
                {translate('ContactInformation')}
              </h6>
              <div className="flex flex-col gap-3">
                <Controller
                  name="CompanyName" // Name of the field
                  control={control} // Pass the register function from useForm
                  defaultValue="" // Set the initial value here if needed
                  render={({field: {onChange, onBlur, value, name, ref}}) => (
                    <TextInput
                      onChange={onChange}
                      onBlur={onBlur}
                      name={name}
                      value={value}
                      ref={ref}
                      placeholder={translate('CompanyName')}
                      isError={errors?.CompanyName}
                    />
                  )}
                />

                <div className="grid lg:grid-cols-3 grid-cols-1 gap-3">
                  <Controller
                    name="CompanyRegistry" // Name of the field
                    control={control} // Pass the register function from useForm
                    defaultValue="" // Set the initial value here if needed
                    render={({field: {onChange, onBlur, value, name, ref}}) => (
                      <TextInput
                        onChange={onChange}
                        onBlur={onBlur}
                        name={name}
                        value={value}
                        ref={ref}
                        placeholder={translate('CompanyRegistrationNumber')}
                        isError={errors?.CompanyRegistry}
                      />
                    )}
                  />
                  <Controller
                    name="CompanyActivity" // Name of the field
                    control={control} // Pass the register function from useForm
                    defaultValue="" // Set the initial value here if needed
                    render={({field: {onChange, onBlur, value, name, ref}}) => (
                      <TextInput
                        onChange={onChange}
                        onBlur={onBlur}
                        name={name}
                        value={value}
                        ref={ref}
                        placeholder={translate('CompanyFieldfActivity')}
                        isError={errors?.CompanyActivity}
                      />
                    )}
                  />
                  <Controller
                    name="Bank" // Name of the field
                    control={control} // Pass the register function from useForm
                    defaultValue="" // Set the initial value here if needed
                    render={({field: {onChange, onBlur, value, name, ref}}) => (
                      <TextInput
                        onChange={onChange}
                        onBlur={onBlur}
                        name={name}
                        value={value}
                        ref={ref}
                        placeholder={translate('BankWhereTheCompanyWorks')}
                        isError={errors?.Bank}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="flex lg:flex-row flex-col lg:mt-5 mt-0 gap-3">
                <Controller
                  name="BoynerType" // Name of the field
                  control={control} // Pass the register function from useForm
                  defaultValue="" // Set the initial value here if needed
                  render={({field: {onChange, onBlur, name, ref}}) => (
                    <RadioButton label="BOYNER" onChange={onChange} onBlur={onBlur} name={name} value="boyner" ref={ref} />
                  )}
                />
                <Controller
                  name="BoynerType" // Name of the field
                  control={control} // Pass the register function from useForm
                  defaultValue="" // Set the initial value here if needed
                  render={({field: {onChange, onBlur, name, ref}}) => (
                    <RadioButton label="BOYNER Sports" onChange={onChange} onBlur={onBlur} name={name} value="boynerSports" ref={ref} />
                  )}
                />
                <Controller
                  name="BoynerType" // Name of the field
                  control={control} // Pass the register function from useForm
                  defaultValue="" // Set the initial value here if needed
                  render={({field: {onChange, onBlur, name, ref}}) => (
                    <RadioButton label="BOYNER Outlet" onChange={onChange} onBlur={onBlur} name={name} value="boynerOutlet" ref={ref} />
                  )}
                />
              </div>

              <h6 className="text-[16px] lg:text-[20px] font-boldie lg:leading-7 leading-5 lg:tracking-[0.4px] tracking-[0.28px] mt-5">
                {translate('ContactInformation')}
              </h6>
              <div className="flex flex-col gap-3">
                <div className="grid lg:grid-cols-3 grid-cols-1 gap-3">
                  <Controller
                    name="M2" // Name of the field
                    control={control} // Pass the register function from useForm
                    defaultValue="" // Set the initial value here if needed
                    render={({field: {onChange, onBlur, value, name, ref}}) => (
                      <TextInput
                        onChange={onChange}
                        onBlur={onBlur}
                        name={name}
                        value={value}
                        ref={ref}
                        placeholder={translate('M2')}
                        isError={errors?.M2}
                      />
                    )}
                  />
                  <Controller
                    name="NumberOfFloors" // Name of the field
                    control={control} // Pass the register function from useForm
                    defaultValue="" // Set the initial value here if needed
                    render={({field: {onChange, onBlur, value, name, ref}}) => (
                      <TextInput
                        onChange={onChange}
                        onBlur={onBlur}
                        name={name}
                        value={value}
                        ref={ref}
                        placeholder={translate('NumberOfFloors')}
                        isError={errors?.NumberOfFloors}
                      />
                    )}
                  />
                  <Controller
                    name="FloorHeight" // Name of the field
                    control={control} // Pass the register function from useForm
                    defaultValue="" // Set the initial value here if needed
                    render={({field: {onChange, onBlur, value, name, ref}}) => (
                      <TextInput
                        onChange={onChange}
                        onBlur={onBlur}
                        name={name}
                        value={value}
                        ref={ref}
                        placeholder={translate('FloorHeight')}
                        isError={errors?.FloorHeight}
                      />
                    )}
                  />
                </div>
                <Controller
                  name="Avm" // Name of the field
                  control={control} // Pass the register function from useForm
                  defaultValue="" // Set the initial value here if needed
                  render={({field: {onChange, onBlur, value, name, ref}}) => (
                    <TextInput
                      onChange={onChange}
                      onBlur={onBlur}
                      name={name}
                      value={value}
                      ref={ref}
                      placeholder={translate('Avm')}
                      isError={errors?.Avm}
                    />
                  )}
                />
                <div className="flex flex-col gap-3">
                  <div className="grid lg:grid-cols-3 grid-cols-1 gap-3">
                    <Controller
                      name="city" // Name of the field
                      control={control} // Pass the register function from useForm
                      defaultValue="" // Set the initial value here if needed
                      render={({field: {ref, name, onChange}}) => (
                        <Dropdown
                          className="lg:flex-1"
                          options={cities}
                          ref={ref}
                          name={name}
                          onChange={(e, city) => {
                            onChange(e);
                            setCity(city);
                          }}
                          defaultLabel={translate('city')}
                          defaultSelected={city}
                          isError={errors?.city}
                        />
                      )}
                    />
                    <Controller
                      name="district" // Name of the field
                      control={control} // Pass the register function from useForm
                      defaultValue="" // Set the initial value here if needed
                      render={({field: {ref, name, onChange}}) => (
                        <Dropdown
                          className="lg:flex-1"
                          options={districts}
                          onChange={(e, district) => {
                            onChange(e);
                            setDistrict(district);
                          }}
                          name={name}
                          ref={ref}
                          defaultLabel={translate('District')}
                          defaultSelected={district}
                          disabled={!city?.id}
                          isError={errors?.district}
                        />
                      )}
                    />
                    <Controller
                      name="town" // Name of the field
                      control={control} // Pass the register function from useForm
                      defaultValue="" // Set the initial value here if needed
                      render={({field: {ref, name, onChange}}) => (
                        <Dropdown
                          className="lg:flex-1"
                          options={towns}
                          onChange={(e, town) => {
                            onChange(e);
                            setTown(town);
                          }}
                          name={name}
                          ref={ref}
                          defaultLabel={translate('town')}
                          defaultSelected={town}
                          disabled={!district?.id}
                          isError={errors?.town}
                        />
                      )}
                    />
                  </div>
                </div>
                <Controller
                  name="Address" // Name of the field
                  control={control} // Pass the register function from useForm
                  defaultValue="" // Set the initial value here if needed
                  render={({field: {onChange, onBlur, value, name, ref}}) => (
                    <TextArea
                      onChange={onChange}
                      onBlur={onBlur}
                      name={name}
                      value={value}
                      ref={ref}
                      placeholder={translate('AddressDetail')}
                      isError={errors?.Address}
                    />
                  )}
                />{' '}
              </div>
              <p className="text-[12px] leading-5 tracking-[0.28px] text-secondary">
                Bizlere ileteceğiniz başvurularınız kapsamında paylaşmış olduğunuz bilgilerde talep edilenin dışında gerekmeyen kişisel bilgilerinizin
                ve özel nitelikli kişisel verilerinizin (Irk, etnik köken, siyasi düşünce, felsefi inanç, din, mezhep veya diğer inançlar, kılık ve
                kıyafet, dernek, vakıf ya da sendika üyeliği, sağlık, cinsel hayata ilişkin veriler, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili
                veriler biyometrik ve genetik veriler) yer almadığından emin olmanızı rica ederiz. Kişisel verilerinizin işlenmesine ilişkin detaylı
                bilgilere{' '}
                <span>
                  <a
                    target="_blank"
                    className="underline"
                    href="https://www.boyner.com.tr/content/kisisel-verilerin-korunmasina-iliskin-aydinlatma-metni">
                    Müşteri, Üye ve Ziyaretçi Kişisel Verilerinin Korunmasına ilişkin Aydınlatma Metni | Boyner
                  </a>
                </span>{' '}
                linkinden ulaşabilirsiniz.
              </p>
            </div>
            <div className="mt-3">
              <ReCAPTCHA
                sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                ref={recaptchaRef}
                hl={selectedLanguage}
                onChange={handleCaptchaSubmission}
              />
              <button
                disabled={!isVerified}
                className="bg-primary mb-10 text-white text-center max-sm:w-full lg:min-w-[280px] mt-9 p-[17px] uppercase disabled:bg-[#E5E5E5] disabled:text-[#BDBDBD] disabled:cursor-not-allowed"
                onClick={onSubmit}>
                {translate('Send')}
              </button>
            </div>
          </form>
          <DevTool control={control} /> {/* set up the dev tool */}
        </div>
      </Drawer>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        title={translate('BecomeABusinessPartnerContactForm')}
        subtitle={translate('ThankYouWeHaveReceivedYourRequest')}
        content={translate('ModalContent')}
        label={translate('Okay')}
      />
    </>
  );
};
export default PartnerForm;
