import React from 'react';
import Image from 'next/image';
import {fileDomain} from '@/config/constants';

const Statistics = ({widgetData = {}}) => {
  const {BackgroundColor, Items} = widgetData;

  return (
    <div
      className={`bg-primary grid grid-cols-2 lg:min-h-[651px] lg:flex items-center justify-center place-items-center lg:gap-6 gap-3 p-[42px] max-md:mt-[42px]`}
      style={{backgroundColor: BackgroundColor}}>
      {Items?.map((item, index) => (
        <div
          key={index}
          className="flex lg:gap-3 gap-2 flex-col items-center border border-white text-white w-[140px] h-[140px] lg:w-[220px] lg:h-[220px] lg:py-[53px] py-[29px] text-center">
          <>
            <p className="hidden sm:block text-white text-[50px] font-boldie leading-[56px] tracking-[1px] py-2">{item?.Text}</p>
            <h2 className="block lg:hidden text-[32px] font-boldie leading-[44px] tracking-[0.64px]">{item?.Title}</h2>
            <Image src={`${fileDomain}/public/boyner-corporate/line.png`} width={24} height={1} alt="" />
            <p className="text-[#999] text-[16px] lg:text-[24px] leading-[22px] lg:leading-[34px] tracking-[0.32px] lg:tracking-[0.48px] py-2">
              {item?.Title}
            </p>
          </>
        </div>
      ))}
    </div>
  );
};
export default Statistics;
