import React, {useMemo} from 'react';
import classNames from 'classnames';

const ROWS_PER_COLUMN = 3;

const NumberedSection = ({widgetData = {}}) => {
  const {Title, Text, Image: ImageUrl, Items} = widgetData;

  const renderFrame = useMemo(() => {
    function splitArray(array, n) {
      let [...arr] = array;
      let res = [];
      while (arr.length) {
        res.push(arr.splice(0, n));
      }
      return res;
    }

    function getIndex(item) {
      const index = Items?.indexOf(item);
      return index < 9 ? `0${index + 1}` : index + 1;
    }

    const columns = splitArray(Items, ROWS_PER_COLUMN);

    return (
      <>
        {columns?.map((rows, key) => {
          return (
            <div
              className={classNames(
                'grid lg:grid-cols-3 grid-cols-1 lg:mt-[120px] z-20 max-xl:gap-x-[100px] gap-x-[207px] min-[2000px]:gap-x-[300px]  max-lg:gap-y-[72px]',
                {
                  'max-lg:bg-primary max-lg:pt-[54px] max-lg:px-4 max-lg:pb-[31px]': key === 0,
                },
              )}
              key={key}>
              {rows.map((item, index) => {
                if (index === 0) {
                  return (
                    <div
                      className={`lg:text-primary max-lg:min-w-[320px] text-white lg:p-4 p-6 ${key >= 1 ? ' max-lg:text-primary' : ''}`}
                      key={index}>
                      <p className="text-[40px] font-boldie leading-[56px] tracking-[0.8px]">{getIndex(item)}</p>
                      <div className={`${key >= 1 ? '' : 'max-md:border-secondary'} border-b border-primary lg:border-[#D0D1D3] mt-6 mb-10`} />
                      <p className="font-medium leading-[22px] uppercase tracking-[0.32px] mb-6">{item.Title}</p>
                      <p className="leading-[22px] tracking-[0.32px]">{item.Text}</p>
                    </div>
                  );
                } else {
                  return (
                    <div className={`text-white max-lg:min-w-[320px] lg:p-4 p-6 ${key >= 1 ? ' max-lg:text-primary' : ''}`} key={index}>
                      <p className="text-[40px] font-boldie leading-[56px] tracking-[0.8px]">{getIndex(item)}</p>
                      <div className="border-b border-secondary mt-6 mb-10" />
                      <p className="font-medium leading-[22px] uppercase tracking-[0.32px] mb-6">{item.Title}</p>
                      <p className="leading-[22px] tracking-[0.32px]">{item.Text}</p>
                    </div>
                  );
                }
              })}
            </div>
          );
        })}
      </>
    );
  }, [Items]);

  return (
    <>
      <div
        className=" relative lg:h-[560px] xl:h-[480px] h-[659px] lg:ml-12 lg:mr-12 mr-6 text-white"
        style={{
          background: `url(${ImageUrl})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}>
        <div className="flex max-lg:flex-col lg:pt-[130px] lg:mt-[120px] mt-16 justify-center lg:gap-[169px] gap-8">
          <p className="flex items-center ml-6 lg:text-[50px] text-[40px] max-lg:pt-[73px] font-boldie tracking-[1px] leading-[56px] lg:leading-normal">
            {Title ? Title : ''}
          </p>
          <p className="flex text-[20px] max-lg:mx-6 leading-7 tracking-[0.4px] lg:w-[464px]">{Text ? Text : ''}</p>
        </div>
      </div>

      <div className="w-full lg:px-12 pl-6 lg:-mt-[124px] -mt-[95px]">
        <div className="relative w-full flex">
          <div className="flex flex-col lg:mx-[98px] lg:pt-[48px] pt-14 lg:pb-[180px] pb-0">{renderFrame}</div>
          <div className="hidden lg:block bg-primary mb-10 lg:w-[61.98%] w-full absolute z-0 top-0 bottom-0 right-0" />
        </div>
      </div>
    </>
  );
};

export default NumberedSection;
