import React from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import Links from '../links';
import {useState} from 'react';
import PopupWidget from '../popupWidget';
import ReactPlayer from 'react-player';

const TextBanner = ({className, widgetData = {}, pageData = []}) => {
  const {BackgroundColor} = widgetData;
  const [isOpen, setIsOpen] = useState(false);

  const [modalData, setModalData] = useState({});
  const openModal = popupId => {
    const data = pageData?.find(item => item.PopupId === parseInt(popupId));
    setModalData(data);
    setIsOpen(true);
  };

  const imageLayout = ({Image: layoutImage, BackgroundColor, Video, Type}) => {
    return (
      <div
        style={{
          backgroundColor: BackgroundColor,
        }}
        className="flex flex-1 relative">
        {Video && Type === 3 ? (
          <div className="h-[414px] md:h-[800px] xl:h-[865px] w-full flex justify-center items-center">
            <ReactPlayer width="100%" height="100%" url={Video} controls className="object-cover object-center" />
          </div>
        ) : (
          <Image src={layoutImage} width={866} height={865} alt="" layout="responsive" />
        )}
      </div>
    );
  };

  const descLayout = ({Title, ButtonText, ButtonTextUrl, ButtonImage, ButtonImageUrl, Text, Items, PopupId}) => (
    <div
      style={{
        backgroundColor: BackgroundColor,
      }}
      className="flex flex-1 flex-col text-primary items-center justify-center  max-lg:pr-0 max-lg:pb-6">
      <div className="lg:ml-[50px]">
        <h2 className="text-[40px] px-4 lg:pl-[64px] pt-6  leading-[56px] lg:tracking-[0.8px] font-[540] max-lg:text-[32px] max-lg:leading-[44px]">
          {Title}
        </h2>
        <p className="lg:mt-4 mt-4 text-[16px] px-4 lg:pr-[64px] lg:pl-[64px] leading-[22px] font-normal lg:text-[24px] tracking-[0.32px] lg:leading-[34px] lg:tracking-[0.48px]">
          {Text}
        </p>
        {!ButtonText && ButtonImage && (
          <Links href={ButtonImageUrl}>
            <Image src={ButtonImage} width={85} height={48} className="mt-[32px] lg:ml-[64px] ml-4 mb-4" alt="" />
          </Links>
        )}
        {ButtonText && (
          <Links
            href={ButtonTextUrl ? ButtonTextUrl : '/'}
            className="flex px-4 lg:pl-[64px] max-lg:ml-4 lg:mt-[32px] text-[16px] leading-[20px]  font-boldie items-center mt-[32px] max-lg:border-[1px] max-lg:inline-flex max-lg:border-primary max-xl:p-[17px]">
            {ButtonText}
            {ButtonImage && <Image src={ButtonImage} width={22} height={22} className="ml-[16px]" alt="button-image" />}
          </Links>
        )}
        <div>
          {Items?.map(({Title, Text, OpenTime, CloseTime, Url}, i) => (
            <>
              <Links
                href={PopupId ? 'javascript:void(0)' : Url ? Url + `?tk=${Title.replace(/ /g, '').toLocaleLowerCase('tr-TR')}` : '/'}
                onClick={PopupId ? () => openModal(PopupId) : null}
                className="lg:text-[24px] max-lg:ml-4 mb-0 lg:mt-[32px] text-[20px] lg:pl-[64px] pl-0 lg:leading-[34px] leading-7 items-center lg:tracking-[0.48px] tracking-[0.4px] flex mt-[32px] max-xl:mt-[25px] max-xl:text-[24px] font-boldie first-of-type:max-xl:mt-[40px]"
                key={i}>
                {Title}
                <i className="icon text-shape-right text-[12px] ml-[12px] text-active" />
              </Links>
              <div className="lg:pl-[64px] max-lg:ml-4 ">
                {Text && (
                  <>
                    <div className="bg-[#BBB] h-px w-[98px] my-3"></div>
                    <p className="lg:text-[20px] text-[16px] text-[#999] lg:leading-[28px] leading-[22px] lg:tracking-[0.4px] tracking-[0.32px]">
                      {Text}
                    </p>
                  </>
                )}
                {OpenTime && CloseTime && (
                  <div className="flex flex-row gap-2 lg:text-[20px] text-[16px] lg:leading-[28px] leading-[22px] lg:tracking-[0.4px] tracking-[0.32px] items-center">
                    {/* <p className="font-boldie text-[#277C2E]">Open</p> */}
                    <p className="text-[#999] mt-1">{`${OpenTime} - ${CloseTime}`}</p>
                  </div>
                )}
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );

  const renderLayout = ({Type, ...data}) => {
    if (Type === 2 || Type === 3) {
      return imageLayout({...data, Type});
    }
    return descLayout(data);
  };

  return (
    <article
      className={classNames(
        'flex px-0 pt-0',
        {
          'max-lg:flex-col': widgetData?.Left?.Type === 2 || widgetData?.Left?.Type === 3,
          'max-lg:flex-col-reverse': widgetData?.Left?.Type !== 2 && widgetData?.Left?.Type !== 3,
        },
        className,
      )}>
      {renderLayout({...widgetData.Left, PopupId: widgetData.PopupId})}
      {renderLayout({...widgetData.Right, PopupId: widgetData.PopupId})}
      <PopupWidget isOpen={isOpen} setIsOpen={setIsOpen} widgetData={modalData} />
    </article>
  );
};

export default TextBanner;
