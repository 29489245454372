// import {useGetWidgetsQuery} from '@/store/services';
import React, {useState} from 'react';

export const Tabs = ({children}) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabValidator = tab => {
    return tab.type.displayName === 'Tab' ? true : false;
  };

  return (
    <>
      <div className="flex justify-center">
        {children.map((item, i) => {
          return (
            tabValidator(item) && (
              <Tab key={`tab-{i}`} currentTab={i} activeTab={activeTab} setActiveTab={setActiveTab}>
                {item.props.children}
              </Tab>
            )
          );
        })}
      </div>
      <div className="p-5">
        {children.map((item, i) => {
          return (
            <div className={` ${i === activeTab ? 'visible' : 'hidden'}`} key={i}>
              {item.props.component}
            </div>
          );
        })}
      </div>
    </>
  );
};

export const Tab = ({children, activeTab, currentTab, setActiveTab, ...props}) => {
  // const {} = useGetWidgetsQuery({type: 1, name: url.replace('/', ''), platform: '1,2,3'});
  return (
    <div
      className={`px-5 py-4 cursor-pointer text-[24px]
      ${
        activeTab === currentTab
          ? 'text-primary border-b-2 border-primary font-boldie leading-[34px] tracking-[0.48px]'
          : 'text-secondary font-normal border-b border-[#D0D1D3]'
      }`}
      onClick={() => setActiveTab(currentTab)}
      {...props}>
      {children}
    </div>
  );
};

Tab.displayName = 'Tab';
