import {useKeenSlider} from 'keen-slider/react';
import {useState} from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const BannerSlider = ({widgetData = {}}) => {
  const {Title, Text, Items, BackgroundColor} = widgetData;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [visibleSlider, setVisibleSlider] = useState(false);

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      duration: 800,
      mode: 'snap',
      rtl: false,
      slides: {perView: 1.3, spacing: 12},
      breakpoints: {
        '(max-width: 1023px)': {
          slides: {perView: 1, spacing: 0},
        },
      },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
    },
    [
      slider => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            Items.length === slider.track.details.rel + 1 ? slider.moveToIdx(0) : slider.next();
          }, 4000);
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false;
            nextTimeout();
          });
          setVisibleSlider(true);
          nextTimeout();
        });
        slider.on('dragStarted', clearNextTimeout);
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      },
    ],
  );

  return (
    <article
      className="w-full bg-[#F8F6F7] lg:pb-[72px] pb-16"
      style={{
        backgroundColor: BackgroundColor,
      }}>
      <div className="flex lg:flex-row lg:flex-row-reverse flex-col sm:max-lg:flex-col mx-auto h-full w-full">
        <div className="flex-1 overflow-hidden xl:h-[828px] lg:h-[628px] flex items-center">
          <div
            ref={sliderRef}
            className={classNames('keen-slider transition-all duration-500 items-center opacity-0', {
              'opacity-100': visibleSlider,
            })}>
            {Items?.map(({Image: sliderImage}, index) => (
              <div
                className={classNames(
                  'keen-slider__slide w-[471px] h-[664px] max-lg:h-[462px] transition-[width, height] transition-[height] duration-200',
                  {
                    ['lg:w-[586px] lg:h-[828px]']: currentSlide === index,
                  },
                )}
                key={index}>
                <Image src={sliderImage} className="transition-all" layout="fill" objectFit="cover" alt="" />
              </div>
            ))}
          </div>
        </div>
        <div className="flex text-primary items-center justify-center xl:pl-[145px] lg:pl-[120px] flex-1">
          <div className="lg:max-w-[707px] max-lg:px-[24px] pr-[30px] max-lg:pt-[16px]">
            <h1 className="xl:text-[64px] text-[31px] leading-[44px] lg:text-[44px] xl:leading-[80px] lg:leading-[60px]">{Title}</h1>
            {Text && <p className="xl:text-[20px] xl:leading-[28px] text-[16px] leading-[22px] lg:mt-[48px] mt-[16px]">{Text}</p>}
            <button
              onClick={e => {
                e.stopPropagation();
                if (Items.length === currentSlide + 1) {
                  instanceRef.current.moveToIdx(0);
                } else instanceRef.current?.next();
              }}
              className="w-[70px] h-[70px] rounded-[50%] bg-white flex items-center justify-center border-[1px] border-[#E5E5E5] shadow-[0_0_8px_0_rgba(0,0,0,0.1)] mt-[48px] max-lg:mt-[16px] relative p-[4px]">
              <CircularProgressbar
                value={(100 / Items.length) * (currentSlide + 1)}
                maxValue={100}
                styles={{
                  root: {
                    width: '66px',
                  },
                  trail: {
                    stroke: 'transparent',
                  },
                  path: {
                    strokeWidth: 2,
                    stroke: '#2B2B38',
                  },
                }}
              />
              <i className="icon right-chevron absolute top-0 bottom-0 right-0 left-0 m-auto w-[22px] h-[22px] text-[22px] text-primary" />
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};

export default BannerSlider;
