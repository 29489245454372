import {useKeenSlider} from 'keen-slider/react';
import Image from 'next/image';
import classNames from 'classnames';
import {useState} from 'react';

const TextSliderItem = ({className, widgetData = {}, isMobile}) => {
  const scrollItems = widgetData?.Items || [];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef] = useKeenSlider({
    loop: false,
    mode: 'snap',
    rtl: false,
    slides: {perView: 'auto', spacing: 0},
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  const scrollItemsMapping = scrollItems.map((value, index) => <Item {...value} key={index} index={index} />);

  return (
    <article className={classNames('flex py-[20px] my-[-20px] overflow-x-auto overflow-y-auto no-scrollbar', className)}>
      {isMobile ? (
        <div ref={sliderRef} className="keen-slider relative">
          {scrollItemsMapping}
          <div className="w-[77px] h-[4px] bg-[rgba(229,229,229,.5)] bottom-[89px] left-[56px] absolute">
            <div
              className=" h-[100%] bg-[#FFFFFF] duration-500"
              style={{
                width: `${(100 / scrollItems.length) * (currentSlide + 1)}%`,
              }}></div>
          </div>
        </div>
      ) : (
        scrollItemsMapping
      )}
    </article>
  );
};

const Item = ({Title, Text, BackgroundImage: ItemImage, index}) => (
  <div className="group keen-slider__slide flex-shrink-0 relative w-[332px] h-[485px] max-lg:h-[539px] border-[#BBBBBB] border-[1px] flex-col font-boldie border-r-0">
    <div className="hover:scale-[1.08] hover:bg-black w-full h-full absolute pt-[170px] hover:pt-[100px] max-lg:bg-black max-lg:!pt-0 max-lg:px-[56px] px-[48px] transition-all duration-500 hover:text-white z-10 max-lg:text-white max-lg:!scale-[1] max-lg:flex max-lg:flex-col max-lg:justify-center">
      <Image
        src={ItemImage}
        objectFit="cover"
        layout="fill"
        alt=""
        className="absolute w-full h-full -z-10 opacity-0 group-hover:opacity-40 transition-all duration-500 max-lg:opacity-30"
      />
      <span className="text-[24px] text-[#BBBBBB] group-hover:text-active max-lg:text-active transition-colors duration-500">
        {index + 1 < 10 ? `0${index + 1}` : index + 1}
      </span>
      <div className="mt-[16px] text-[24px] block">{Title}</div>
      <p className="opacity-0 group-hover:opacity-100 leading-[22px] mt-[16px] font-normal transition-[opacity] max-lg:opacity-100">{Text}</p>
    </div>
  </div>
);

export default TextSliderItem;
