import React from 'react';
import BreadCrumb from '../breadCrumb';

const SharedHeaderBanner = ({widgetData}) => {
  const {Title, WebBackgroundImage, MobileBackgroundImage, Breadcrumb, TitleColorType} = widgetData;

  const renderDefault = (
    <div
      className={`grid relative h-[240px] flex-col lg:bg-[image:var(--desktop-image-url)] bg-[image:var(--mobile-image-url)]`}
      style={{
        '--desktop-image-url': `url(${WebBackgroundImage})`,
        '--mobile-image-url': `url(${MobileBackgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        categoryTitle: `(${Breadcrumb})`,
      }}>
      <div className="absolute opacity-70 w-full h-full bg-gradient-to-br from-[#2F2F44] from-0% via-[#0E0E12] via-53% to-black to-100%" />
      <div className="text-center mt-auto mb-12 lg:mb:0">
        {Title && (
          <>
            <h2 className="hidden max-sm:block relative text-white font-boldie text-[32px] leading-[44px] tracking-[0.64px]">{Title}</h2>
            <h1 className="hidden sm:block text-white leading-[56px] relative text-[40px] font-[540] tracking-[0.8px]">{Title}</h1>
          </>
        )}
        <BreadCrumb />
      </div>
    </div>
  );

  const renderLinear = (
    <div
      className={`grid relative h-[646px] lg:h-[876px] w-full flex-col justify-items-center lg:bg-[image:var(--desktop-image-url)] bg-[image:var(--mobile-image-url)]`}
      style={{
        '--desktop-image-url': `url(${WebBackgroundImage})`,
        '--mobile-image-url': `url(${MobileBackgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        categoryTitle: `(${Breadcrumb})`,
      }}>
      <div className="absolute opacity-70 w-full h-full bg-gradient-to-br from-[#2F2F44] from-0% via-[#0E0E12] via-53% to-black to-100%" />
      <div className="lg:w-[952px] lg:h-[224px] sm:h-[646px] relative lg:mt-[227px] mt-[93px] max-sm:mx-6 flex flex-col">
        {Title && (
          <h2 className="hidden max-sm:block text-transparent bg-clip-text bg-gradient-to-br from-[#FFC0C0] to-[#BFC8FF] text-center font-boldie text-[32px] leading-[44px] tracking-[0.64px]">
            {Title}
          </h2>
        )}
        {Title && (
          <h1 className="hidden sm:block text-transparent bg-clip-text bg-gradient-to-br from-[#FFC0C0] to-[#BFC8FF] text-center font-boldie text-[40px] leading-[56px] tracking-[0.8px]">
            {Title}
          </h1>
        )}
        <BreadCrumb />
      </div>
    </div>
  );

  return TitleColorType === '1' ? renderLinear : renderDefault;
};

export default SharedHeaderBanner;
