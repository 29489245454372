import React, {useState, useMemo, useEffect} from 'react';
import {AboutUsOurCaptainsCard} from '@/components';
import PopupWidget from '../popupWidget';

const Organization = ({widgetData = {}, pageData = []}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [selected, setSelected] = useState({});
  const {Organizations} = widgetData;

  const openModal = popupId => {
    const data = pageData?.find(item => item?.PopupId === parseInt(popupId));
    setModalData(data);
    setIsOpen(true);
  };

  useEffect(() => {
    if (selected?.Id) {
      openModal(selected?.Id);
    } else {
      setModalData({});
    }
  }, [selected]);

  const renderFrame = useMemo(() => {
    return (
      <div className="flex flex-col items-center lg:pt-[112px] pt-10 lg:gap-20 gap-10">
        {Organizations?.map((item, index) => (
          <div key={index} className="block lg:flex lg:flex-row flex-col justify-center lg:items-start max-sm:px-6 relative gap-[72px]">
            {item.Items.map((person, i) => (
              <AboutUsOurCaptainsCard key={i} setSelected={setSelected} selected={selected} person={person} setIsOpen={setIsOpen} isOpen={isOpen} />
            ))}
          </div>
        ))}
      </div>
    );
  }, [Organizations]);

  return (
    <div>
      {renderFrame}
      {<PopupWidget isOpen={isOpen} setIsOpen={setIsOpen} widgetData={modalData} />}
    </div>
  );
};

export default Organization;
