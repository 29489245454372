import React, {memo, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Drawer = ({isOpen, setIsOpen, children}) => {
  const drawerContentRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
      // Reset the scroll position for the content inside the drawer
      drawerContentRef.current.scrollTop = 0;
    }
  }, [isOpen, drawerContentRef]);

  return (
    <>
      {isOpen && <div className="fixed z-20 inset-0 bg-gray-700 bg-opacity-50" onClick={() => setIsOpen && setIsOpen(false)}></div>}
      <div
        className={classNames(
          'top-0 right-0 lg:w-[974px] w-full bg-white text-primary fixed overflow-hidden h-screen z-50 ease-in-out duration-300 box-border',
          {
            'translate-x-0': isOpen,
            'translate-x-full': !isOpen,
          },
        )}>
        <div ref={drawerContentRef} className="h-full overflow-y-auto">
          {children}
        </div>
      </div>
    </>
  );
};

Drawer.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  selected: PropTypes.object,
};

export default memo(Drawer);
