import React, {useEffect, useState, useRef} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Drawer from '../drawer';
import {useForm, Controller} from 'react-hook-form';
import {DevTool} from '@hookform/devtools';
import {TextArea, TextInput} from '../inputs';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {Modal} from '@/components';
import {useLanguage} from '@/hooks/LanguageContext';
import {useSendEmailMutation} from '@/store/services';

const schema = yup
  .object({
    FirstName: yup.string().required('İsim alanı boş bırakılamaz.'),
    LastName: yup.string().required('Soyisim alanı boş bırakılamaz.'),
    Phone: yup.string().matches(new RegExp('[0-9]{7}')),
    Email: yup.string().email('Email formatına uygun olmalıdır.').required('Email alanı boş bırakılamaz.'),
    Message: yup.string().required('Mesaj alanı boş bırakılamaz.'),
  })
  .required();

const WholesaleContactForm = ({isOpen, setIsOpen}) => {
  const recaptchaRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const {translate, selectedLanguage} = useLanguage();
  const [sendEmail, {isSuccess}] = useSendEmailMutation();

  const {
    control,
    reset,
    trigger,
    getValues,
    formState: {errors},
  } = useForm({resolver: yupResolver(schema)});

  async function handleCaptchaSubmission(token) {
    try {
      await fetch('/api/validate-recaptcha', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({token: token}),
      });
      setIsVerified(true);
    } catch (e) {
      setIsVerified(false);
    }
  }

  const onSubmit = async () => {
    const formValues = getValues();
    const triggered = await trigger();
    if (triggered) {
      await sendEmail({data: JSON.stringify({...formValues, emailType: 0})});
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setIsOpen(false);
      setShowModal(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isOpen) {
      reset();
      recaptchaRef.current.reset();
      setIsVerified(false);
    }
  }, [isOpen]);

  return (
    <>
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className=" overflow-y-auto h-screen">
          <div className="hidden lg:block lg:flex items-end justify-end mt-6 mr-6 lg:mt-8 lg:mr-8">
            <button onClick={() => setIsOpen(false)}>
              <i className="icon close text-primary ml-auto text-2xl" />
            </button>
          </div>
          <div className="flex flex-row lg:pl-0 py-3 pl-4 items-center">
            <button onClick={() => setIsOpen(false)} className="hidden max-lg:block">
              <i className="icon left-chevron text-[26px]" />
            </button>
            <p className="text-center text-[18px] lg:text-[40px] ml-[26px] lg:ml-[50px] lg:leading-[56px] font-boldie leading-[22px] lg:tracking-[0.8px] tracking-[0.36px] text-primary">
              {translate('WholesaleContactForm')}
            </p>
          </div>
          <form className="mt-5 lg:mt-8 lg:mx-[50px] mx-4" onSubmit={e => e.preventDefault()}>
            <h6 className="text-[16px] lg:text-[20px] font-boldie lg:leading-7 leading-5 lg:tracking-[0.4px] tracking-[0.28px] mb-4">
              {translate('ContactInformation')}
            </h6>
            <div className="flex flex-col gap-3">
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-3">
                <Controller
                  name="FirstName" // Name of the field
                  control={control} // Pass the register function from useForm
                  defaultValue="" // Set the initial value here if needed
                  render={({field: {onChange, onBlur, value, name, ref}}) => (
                    <TextInput
                      onChange={onChange}
                      onBlur={onBlur}
                      name={name}
                      value={value}
                      ref={ref}
                      placeholder={translate('YourName')}
                      isError={errors?.FirstName}
                    />
                  )}
                />
                <Controller
                  name="LastName" // Name of the field
                  control={control} // Pass the register function from useForm
                  defaultValue="" // Set the initial value here if needed
                  render={({field: {onChange, onBlur, value, name, ref}}) => (
                    <TextInput
                      onChange={onChange}
                      onBlur={onBlur}
                      name={name}
                      value={value}
                      ref={ref}
                      placeholder={translate('YourSurname')}
                      isError={errors?.LastName}
                    />
                  )}
                />
              </div>
              <Controller
                name="Phone" // Name of the field
                control={control} // Pass the register function from useForm
                defaultValue="" // Set the initial value here if needed
                render={({field: {onChange, onBlur, value, name, ref}}) => (
                  <TextInput
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    value={value}
                    ref={ref}
                    placeholder={translate('YourPhone')}
                    isError={errors?.Phone}
                  />
                )}
              />
              <Controller
                name="Email" // Name of the field
                control={control} // Pass the register function from useForm
                defaultValue="" // Set the initial value here if needed
                render={({field: {onChange, onBlur, value, name, ref}}) => (
                  <TextInput
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    value={value}
                    ref={ref}
                    placeholder={translate('Email')}
                    isError={errors?.Email}
                  />
                )}
              />
              <Controller
                name="Message" // Name of the field
                control={control} // Pass the register function from useForm
                defaultValue="" // Set the initial value here if needed
                render={({field: {onChange, onBlur, value, name, ref}}) => (
                  <TextArea
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    value={value}
                    ref={ref}
                    placeholder={translate('YourMessage')}
                    isError={errors?.Message}
                  />
                )}
              />
              <p className="text-[12px] leading-5 tracking-[0.28px] text-secondary">
                Bizlere ileteceğiniz başvurularınız kapsamında paylaşmış olduğunuz bilgilerde talep edilenin dışında gerekmeyen kişisel bilgilerinizin
                ve özel nitelikli kişisel verilerinizin (Irk, etnik köken, siyasi düşünce, felsefi inanç, din, mezhep veya diğer inançlar, kılık ve
                kıyafet, dernek, vakıf ya da sendika üyeliği, sağlık, cinsel hayata ilişkin veriler, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili
                veriler biyometrik ve genetik veriler) yer almadığından emin olmanızı rica ederiz. Kişisel verilerinizin işlenmesine ilişkin detaylı
                bilgilere{' '}
                <span>
                  <a
                    target="_blank"
                    className="underline"
                    href="https://www.boyner.com.tr/content/kisisel-verilerin-korunmasina-iliskin-aydinlatma-metni">
                    Müşteri, Üye ve Ziyaretçi Kişisel Verilerinin Korunmasına ilişkin Aydınlatma Metni | Boyner
                  </a>
                </span>{' '}
                linkinden ulaşabilirsiniz.
              </p>
            </div>
            <div className="mt-3">
              <ReCAPTCHA
                sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                ref={recaptchaRef}
                hl={selectedLanguage}
                onChange={handleCaptchaSubmission}
              />
              <button
                disabled={!isVerified}
                className="bg-primary mb-10 text-white text-center max-sm:w-full lg:min-w-[280px] mt-9 p-[17px] uppercase disabled:bg-[#E5E5E5] disabled:text-[#BDBDBD] disabled:cursor-not-allowed"
                onClick={onSubmit}>
                {translate('Send')}
              </button>
            </div>
          </form>
        </div>
        <DevTool control={control} /> {/* set up the dev tool */}
      </Drawer>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        title={translate('WholesaleContactForm')}
        subtitle={translate('ThankYouWeHaveReceivedYourRequest')}
        content={translate('ModalContent')}
        label={translate('Okay')}
      />
    </>
  );
};
export default WholesaleContactForm;
