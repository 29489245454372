import Image from 'next/image';
import Links from '../links';
import {useLanguage} from '@/hooks/LanguageContext';
import {fileDomain} from '@/config/constants';

const ListWidget = ({className, widgetData = {}}) => {
  const {Items, BackgroundColor, Title: mainTitle, KariyerNetUrl, LinkedInUrl} = widgetData;
  const {translate} = useLanguage();

  return (
    <div style={{backgroundColor: BackgroundColor}} className="sm:flex flex-col items-center max-md:px-4 lg:py-[144px] py-[52px]">
      <div className="lg:flex items-center lg:justify-center">
        {mainTitle && <h2 className="text-[40px] font-boldie leading-[56px] text-[#2B2B38] w-[335px] max-md:mb-6">{mainTitle}</h2>}

        <div className={`grid lg:grid-cols-2 md:grid-cols-1 gap-0 ${className || ''}`}>
          {Items?.map(({Title: valueTitle, Text, Url}, index) => (
            <div key={index} className="flex flex-col flex-start border p-6  bg-white">
              {valueTitle && (
                <Links
                  target="_bank"
                  href={Url}
                  className="text-[24px] lg:mt-[62px] sm:mt-5  lg:ml-[50px] sm:ml-3 text-black with-underline relative after:content-'' after:absolute after:bottom-[-6px] after:left-0 after:w-[98px] after:h-[1px] after:bg-gray-300">
                  {valueTitle}
                  <i className="icon text-shape-right text-active text-[12px] ml-3"></i>
                </Links>
              )}

              <div className="text-[#999] leading-7 tracking-[0.4px] mt-[12px] sm:mb-5 lg:mb-[66px] lg:ml-[50px] sm:ml-3 text-[20px]">{Text}</div>
            </div>
          ))}

          <div className="flex flex-col border bg-white justify-center content-center gap-[50px] text-[#2B2B38] font-boldie underline px-3 lg:px-[67px] py-[30px] lg:py-[66px]">
            {LinkedInUrl && (
              <div className="flex flex-row items-start justify-between">
                <Image src={`${fileDomain}/public/boyner-corporate/linkedin-icon.png`} alt="Icon 1" className="icon" width={85} height={24} />
                <Links target="_bank" href={`${LinkedInUrl}`}>
                  <span>{translate('SeeAll')}</span>
                </Links>
              </div>
            )}
            {KariyerNetUrl && (
              <div className="flex flex-row items-start justify-between">
                <Image src={`${fileDomain}/public/boyner-corporate/kariyer-icon.png`} alt="Icon 1" className="icon" width={119} height={28} />
                <Links target="_bank" href={`${KariyerNetUrl}`}>
                  <span>{translate('SeeAll')}</span>
                </Links>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListWidget;
