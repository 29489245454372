import React, {useEffect, useRef, useState} from 'react';
import {useGetWidgetsQuery} from '@/store/services';
import DynamicWidget from '../dynamicWidget';
import {useLanguage} from '@/hooks/LanguageContext';
import {useRouter} from 'next/router';

const TabWidget = ({widgetData = {}}) => {
  const router = useRouter();
  const tabContainer = useRef(null);
  const [active, setActive] = useState(0);
  const {Tabs: tabs} = widgetData;

  useEffect(() => {
    if (router.query?.tk) {
      const index = tabs?.findIndex(item => item.Title?.replace(/ /g, '').toLocaleLowerCase('tr-TR') === router.query?.tk);
      setActive(index === -1 ? 0 : index);
      window.scrollTo({top: tabContainer?.current?.offsetTop, behavior: 'smooth'});
    }
  }, [router.query?.tk, tabs, tabContainer]);

  const handleChange = index => {
    router.push({pathname: router.query.slug, query: {tk: tabs[index]?.Title?.replace(/ /g, '').toLocaleLowerCase('tr-TR')}}, undefined, {
      shallow: true,
    });
    setActive(index);
  };

  return (
    <>
      <div ref={tabContainer} className="flex items-center justify-center">
        <div
          className={`flex overflow-x-auto border-secondary border-b text-center  my-6 lg:text-[24px] text-[12px] lg:leading-[34px] leading-[16px] lg:tracking-[0.48px] tracking-[0.24px]`}>
          {tabs?.map((tab, index) => (
            <button
              key={index}
              className={`flex items-center grow-0 text-center lg:px-8 py-4 px-5 focus:outline-none max-md:w-[125px] ${
                active === index ? 'font-boldie text-primary border-b-2 border-primary ' : 'text-secondary'
              }`}
              onClick={() => handleChange(index)}>
              {tab.Title}
            </button>
          ))}
        </div>
      </div>
      <Item url={tabs[active]?.Url} />
    </>
  );
};

const Item = ({url}) => {
  const {selectedLanguage} = useLanguage();
  const {data} = useGetWidgetsQuery({type: 8, name: url?.replace('/', ''), platform: '1,2,3', lang: selectedLanguage});
  return <DynamicWidget data={data} notTabWidget={true} />;
};

export default TabWidget;
