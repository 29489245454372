import React, {useState} from 'react';

const Dropdown = ({options = [], defaultSelected, defaultLabel, onChange, disabled = false, className, name, isError, ...other}) => {
  const [selected, setSelected] = useState(defaultSelected);

  const handleChange = event => {
    setSelected(event.target.value);

    const selectedObj = options.find(option => option.id === event.target.value);
    if (selectedObj) {
      onChange && onChange(event, selectedObj);
    }
  };

  return (
    <div className={`${isError ? 'dropdown-error' : 'dropdown-base'} relative w-full lg:max-w-sm ${className}`}>
      <select disabled={disabled} value={selected} name={name} onChange={handleChange} {...other} className={`w-full lg:max-w-sm r-0`}>
        {defaultLabel && (
          <option selected hidden value={-1}>
            {defaultLabel}
          </option>
        )}
        {options.map(option => {
          return (
            <option key={option.id} value={option.id}>
              {option.value}
            </option>
          );
        })}
      </select>
    </div>
  );
};
export default Dropdown;
