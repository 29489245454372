import Image from 'next/image';

const Catalog = ({widgetData = {}}) => {
  const {Items} = widgetData;

  return (
    <div className="grid lg:grid-cols-3 gap-[24px] sm:grid-cols-1 lg:mx-[61px] mx-6 mt-[72px] lg:mt-[112px] mb-[64px]">
      {Items &&
        Items?.map((item, index) => (
          <div key={index} className="flex flex-col gap-4 items-center text-center mb-[48px] lg:w-[464px]">
            <div className="hidden sm:block">
              <Image src={item?.Image} width={342} height={240} alt=""></Image>
            </div>
            <div className="hidden max-sm:block">
              <Image src={item?.Image} width={160} height={113} alt=""></Image>
            </div>
            {item.Subtitle && (
              <p className="text-[14px] lg:text-[20px] font-boldie leading-5 lg:leading-7 tracking-[0.28px] lg:tracking-[0.8px] text-primary">
                {item?.Subtitle}
              </p>
            )}
            {item.Title && (
              <p className="text-[14px] lg:text-[24px] font-boldie leading-5 lg:leading-[34px] tracking-[0.28px] lg:tracking-[0.48px] text-primary">
                {item?.Title}
              </p>
            )}
            {item.Text && (
              <p className="text-[12px] lg:text-[14px] font-boldie leading-4 lg:leading-5 tracking-[0.24px] lg:tracking-[0.28px] text-secondary">
                {item?.Text}
              </p>
            )}
            {item.AddTitle && (
              <p className="text-[20px] lg:text-[40px] leading-7 lg:leading-normal tracking-[0.4px] lg:tracking-[0.8px] text-primary">
                {item?.AddTitle}
              </p>
            )}
          </div>
        ))}
    </div>
  );
};

export default Catalog;
