import React, {useState, useEffect, useRef} from 'react';

const BreadCrumbDropdown = ({label, options = []}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div ref={dropdownRef} className="relative inline-block text-left items-center">
      <button
        onClick={toggleDropdown}
        type="button"
        className="inline-flex justify-center w-full text-white text-sm font-medium"
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true">
        {label}
        <i className="icon right-chevron rotate-90 ml-2 text-white text-base mt-1" />
      </button>

      {isOpen && (
        <div
          className="origin-top-right absolute left-0 mt-2 w-56 shadow-lg bg-white"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu">
          <div className="py-1" role="none">
            {options.map(({name, url}, index) => (
              <a key={index} href={url} className="block rounded-lg px-6 py-2 text-gray-500 hover:bg-gray-50 hover:text-gray-700" role="menuitem">
                {name}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BreadCrumbDropdown;
