import Links from '../links';

const SharedLinkFrame = ({widgetData = {}}) => {
  const {LeftText, LeftUrl, RightText, RightUrl} = widgetData;

  return (
    <div className="flex flex-col items-start gap-16 my-[72px] lg:w-[952px] ml-auto mr-auto">
      <div className="flex justify-between w-full px-[23px] lg:px-0">
        {LeftText && (
          <Links
            className="flex items-center justify-center text-[16px] lg:text-[32px] leading-[22px] lg:leading-normal tracking-[0.32px] lg:tracking-[0.64px] text-primary"
            href={LeftUrl ? LeftUrl : '/'}>
            <i className="icon text-shape-left text-[12px] text-active mr-3" />
            {LeftText}
          </Links>
        )}

        {RightText && (
          <Links
            className="flex items-center justify-center text-[16px] lg:text-[32px] leading-[22px] lg:leading-normal tracking-[0.32px] lg:tracking-[0.64px] text-primary max-sm:pl-[104px]"
            href={RightUrl ? RightUrl : '/'}>
            {RightText}
            <i className="icon text-shape-right text-[12px] text-active ml-3" />
          </Links>
        )}
      </div>
    </div>
  );
};

export default SharedLinkFrame;
