import Image from 'next/image';

const AboutUsOurCaptainsCard = ({person, setSelected, setIsOpen, isOpen}) => {
  return (
    <div className="flex flex-col lg:px-0 mb-10 lg:mb-0 relative items-center">
      <Image className="rounded-[6px] border border-transparent" src={person.Image} width={300} height={300} alt="image" />
      <div className="px-[23px] lg:px-0 cursor-pointer ">
        <div
          onClick={() => {
            setSelected(person);
            setIsOpen(!isOpen);
          }}
          className="flex  mt-8 text-center items-center justify-center">
          <h2 className="text-[24px] lg:text-[32px] font-boldie leading-[34px] lg:leading-[44px] tracking-[0.48] lg:tracking-[0.64px]">
            {person.Title}
          </h2>
          <i className="icon text-shape-right text-active text-[12px] ml-3"></i>
        </div>
        <h3 className="text-[16px] lg:max-w-[300px] max-w-[328px] lg:text-[18px] leading-[24px] lg:leading-[32px] tracking-[0.27] lg:tracking-[0.36px] text-center text-secondary">
          {person.Name}
        </h3>
      </div>
    </div>
  );
};

export default AboutUsOurCaptainsCard;
