import Image from 'next/image';
import {useKeenSlider} from 'keen-slider/react';
import {useState} from 'react';
import PopupWidget from '../popupWidget';

const NewsSlider = ({widgetData = {}, isMobile, pageData = []}) => {
  const {Title, Items} = widgetData;
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: false,
    mode: 'free',
    rtl: false,
    slides: {perView: isMobile ? 1.2 : 'auto', spacing: 12},
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  const openModal = popupId => {
    const data = pageData?.find(item => item.PopupId === parseInt(popupId));
    setModalData(data);
    setIsOpen(true);
  };

  return (
    <article className="my-[72px] max-lg:my-[56px]">
      <div className="px-[144px] mb-[32px] flex justify-between max-lg:px-[24px]">
        <h2 className="text-title leading-title text-primary max-lg:text-mobileTitle max-lg:leading-mobileTitle">{Title}</h2>
        <div className="max-lg:hidden">
          <button
            className="text-[32px] text-primary ml-[32px] disabled:text-[#bbbbbb]"
            onClick={e => e.stopPropagation() || instanceRef?.current?.prev()}
            disabled={currentSlide === 0}>
            <i className="icon right-arrow rotate-180 block" />
          </button>
          <button
            className="text-[32px] text-primary ml-[32px] disabled:text-[#bbbbbb]"
            onClick={e => e.stopPropagation() || instanceRef.current?.next()}
            disabled={currentSlide === instanceRef?.current?.track.details?.maxIdx}>
            <i className="icon right-arrow" />
          </button>
        </div>
      </div>
      <div ref={sliderRef} className="keen-slider">
        {Items?.map(({Title, Text, Image: newsImage, Id}, index) => (
          <div
            className="group keen-slider__slide min-w-[464px] first-of-type:pl-[144px] lg:first-of-type:min-w-[608px] max-lg:first-of-type:pl-[24px]"
            key={index}
            onClick={Id ? () => openModal(Id) : null}>
            <div className="w-full h-[504px] max-lg:h-[310px] relative after:bg-[url(/images/newsShadow.png)] after:bg-no-repeat after:bg-[length:100%_100%] after:absolute after:w-full after:h-full after:top-0 after:opacity-75 after:transition-all duration-500 hover:after:opacity-100 after:pointer-events-none after:left-0 after:pointer-events-none">
              <Image src={newsImage} layout="fill" objectFit="cover" alt="" />
              <div className="absolute -bottom-[20px] group-hover:bottom-[32px] max-lg:bottom-[24px] w-full px-[32px] text-white flex flex-col z-10 transition-all">
                <h4 className="text-[24px] leading-[34px] max-lg:text-[20px] max-lg:leading-[38px]">{Title}</h4>
                <p className="text-[16px] leading-[22px] mt-[12px] max-lg:text-[14px] max-lg:leading-[20px]">{Text}</p>
                <button
                  onClick={Id ? () => openModal(Id) : null}
                  className="text-active max-lg:hidden text-[12px] w-[44px] bg-white flex items-center justify-center h-[44px] overflow-hidden mt-[24px] transition-all opacity-0 group-hover:opacity-100">
                  <i className="icon text-shape-right" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {<PopupWidget isOpen={isOpen} setIsOpen={setIsOpen} widgetData={modalData} />}
    </article>
  );
};

export default NewsSlider;
