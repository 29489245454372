import React, {useState} from 'react';
import PopupWidget from '../popupWidget';
import Image from 'next/image';

const VerticalGrid = ({widgetData = {}, pageData = []}) => {
  const {Items} = widgetData;
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const openModal = popupId => {
    const data = pageData?.find(item => item.PopupId === parseInt(popupId));
    setModalData(data);
    setIsOpen(true);
  };

  return (
    <div className="flex flex-col items-center max-w-[1731px] mx-auto mt-[72px]">
      <div className="grid lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-2 gap-x-[24px] gap-y-[46px]">
        {Items?.map(({Image: valueImage, Title, Text, Id}, index) => (
          <div
            onClick={Id ? () => openModal(Id) : null}
            key={index}
            className="group keen-slider__slide  w-[343px] lg:w-[300px] xl:w-[464px] md:w-[350px] h-[504px] max-lg:h-[343px] relative after:bg-[url(/images/newsShadow.png)] after:bg-no-repeat after:bg-[length:100%_100%] after:absolute after:w-full after:h-full after:top-0 after:opacity-75 after:transition-all duration-500 hover:after:opacity-100 after:pointer-events-none after:left-0">
            {valueImage && <Image src={valueImage} layout="fill" objectFit="cover" alt="image" />}
            <div className="absolute -bottom-[20px] group-hover:bottom-[32px] max-lg:bottom-[24px] w-full px-[32px] text-white flex flex-col z-10 transition-all">
              <h4 className="text-[24px] leading-[34px] max-lg:text-[20px] max-lg:leading-[38px]">{Title}</h4>
              <p className="text-[16px] leading-[22px] mt-[12px] max-lg:text-[14px] max-lg:leading-[20px]">{Text}</p>
              <button className="text-active max-lg:hidden text-[12px] w-[44px] bg-white flex items-center justify-center h-[44px] overflow-hidden mt-[24px] transition-all opacity-0 group-hover:opacity-100">
                <i className="icon text-shape-right" />
              </button>
            </div>
          </div>
        ))}
      </div>
      <PopupWidget isOpen={isOpen} setIsOpen={setIsOpen} widgetData={modalData} />
    </div>
  );
};

export default VerticalGrid;
