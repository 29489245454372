import React from 'react';

export const TextInput = ({placeholder, name, className, onChange, isError, ...other}) => {
  return (
    <input
      className={`${className} ${isError ? 'input-error' : 'input-base'}`}
      placeholder={placeholder}
      type="text"
      name={name}
      onChange={onChange}
      {...other}
    />
  );
};

export const TextArea = ({name, placeholder, rows, cols, className, onChange, isError, ...other}) => {
  return (
    <textarea
      className={`${className} ${isError ? 'input-error' : 'input-base'}`}
      name={name}
      placeholder={placeholder}
      rows={rows}
      cols={cols}
      onChange={onChange}
      {...other}
    />
  );
};

export const RadioButton = ({label, name, className, value, onChange, ...other}) => {
  return (
    <div className={`flex flex-row items-center ${className}`}>
      <input className={`radio-base`} type="radio" name={name} value={value} onChange={onChange} {...other} />
      <p className="text-secondary ml-3 text-[14px] leading-5 tracking-[0.28px]">{label}</p>
    </div>
  );
};
