import {
  SharedHeaderBanner,
  Catalog,
  BannerSlider,
  NavigationList,
  SharedLinkFrame,
  Button,
  Roadmap,
  Organization,
  VerticalGrid,
  NumberedSection,
  ListWidget,
  DynamicGrid,
  TextSliderItem,
  HtmlWidget,
  TextBanner,
  Statistics,
  NavigationCategory,
  FormNavigation,
  BusinessInformation,
  NewsSlider,
  TabWidget,
  Career,
} from '@/components';

export const constants = {
  bannerSlider: 36,
  navigationList: 44,
  catalog: 38,
  linkFrame: 39,
  headerBanner: 31,
  button: 42,
  roadmap: 35,
  organization: 37,
  newsSlider: 33,
  numeric: 40,
  list: 41,
  dynamicGrid: 21,
  verticalTextGrid: 32,
  textBanner: 34,
  htmlWidget: 45,
  statistics: 50,
  navigationCategory: 48,
  formNavigation: 51,
  businessInformation: 52,
  tabs: 47,
  verticalGrid: 53,
  career: 54,
};

export const widgets = {
  [constants.headerBanner]: SharedHeaderBanner,
  [constants.bannerSlider]: BannerSlider,
  [constants.navigationList]: NavigationList,
  [constants.catalog]: Catalog,
  [constants.linkFrame]: SharedLinkFrame,
  [constants.button]: Button,
  [constants.roadmap]: Roadmap,
  [constants.organization]: Organization,
  [constants.newsSlider]: NewsSlider,
  [constants.numeric]: NumberedSection,
  [constants.list]: ListWidget,
  [constants.dynamicGrid]: DynamicGrid,
  [constants.verticalTextGrid]: TextSliderItem,
  [constants.textBanner]: TextBanner,
  [constants.htmlWidget]: HtmlWidget,
  [constants.statistics]: Statistics,
  [constants.navigationCategory]: NavigationCategory,
  [constants.formNavigation]: FormNavigation,
  [constants.businessInformation]: BusinessInformation,
  [constants.tabs]: TabWidget,
  [constants.verticalGrid]: VerticalGrid,
  [constants.career]: Career,
};

export default widgets;
