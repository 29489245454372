import {useGetWidgetsQuery} from '@/store/services';
import Widgets from './widgetConst';

const DynamicWidget = ({serviceParam, data: propsData, isMobile}) => {
  const {data = []} = useGetWidgetsQuery(serviceParam, {skip: !serviceParam});
  const widgetData = propsData || data || [];

  return (
    <section>
      {widgetData?.map((v, i) => {
        const Widget = Widgets?.[v.WidgetType];
        return Widget ? <Widget key={i} widgetData={v} pageData={widgetData} isMobile={isMobile} /> : null;
      })}
    </section>
  );
};

export default DynamicWidget;
